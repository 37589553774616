@import url(//fonts.googleapis.com/css?family=Open+Sans);

$error-color: #FF0000;
$error-bg: #FFEEEE;

body {
    font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.field-validation-error
{
    color: $error-color;
}

.field-validation-valid
{
    display: none;
}

.input-validation-error
{
    border: 1px solid $error-color !important;
    background-color: $error-bg;
}

.footer {
    padding-top: 60px;
}

.bold
{
    font-weight: bold;
}

.hide {
    display: none;
}

.page-header {
    margin: 10px 0 15px;
    border: none;

    @media (max-width: 980px) {
        margin: 0;
    }
}

.navbar {margin-bottom: 0;}

.table{
	display:table;	/* Defines a Table */
	font-family:"Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-size:14px;
	border-bottom:2px solid #dddddd;
	color:#000;
	margin:10px 0;
}
.table-head{
	 display: table-header-group; /* Defines a table header group */
}
.table-head .column{ /* Column inside the table-head */
	background:#333333;
	color:#fff;
	border-bottom:none;
}
.table-head .column:hover{ /* Column hover inside the table-head */
	background:#222222;
}
.table-row{
	display:table-row; /* Defines a table row */
}
.table-row:last-child .column{  /* column in a last row */
	border-bottom:none;
}
.column{
	display:table-cell; /* Defines a table cell */
	padding:10px 20px;
}
.column:hover{
	background:#f9f9f9;
}
/* Responsive table */
@media all and (max-width: 640px){
    .mvc-grid {
        margin-left: -15px;
        margin-right: -15px;
    }
	.table,
	.table-row,
	.column,
	.column:before{
		display:block;	/* Converts a table, table row, table column and table column:before into a block element */
	}
	.table,
	.table-row .column:last-child{
		border-bottom:none;
	}
	.table-head{
		position:absolute;	/* Hides table head but not using display none */
		top:-1000em;
		left:-1000em;
	}
	.table-row{
		margin: 0 0 50px;
	}
	.table-row .column:nth-child(1){ /* first column of the row */
		border-left:none;
	}
	.table-row .column:last-child{ /* last column of the row */
		border-right:none;
	}
	.table-row:last-child .column,
	.column{ /* Column in the last row and column */
        border-bottom: none;
        border-right: none;
	}
	.column:before{ /* prints the value of data-label attribute before the column data */
		font-weight:bold;
		padding-right:20px;
		font-size:12px;
		content:""attr(data-label)"";	/* call the attribute value of data-label and adds a string // */
	}
}

.btn-action {
  color: #ffffff;
  background-color: #0F1221;
  border-color: #0F1221;
  border-radius: 0;
  margin: 10px 0;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

.btn-action:hover,
.btn-action:focus,
.btn-action:active,
.btn-action.active,
.open .dropdown-toggle.btn-action {
  color: #000;
  background-color: #eee;
  border-color: #0F1221;
}

.btn-action:active,
.btn-action.active,
.open .dropdown-toggle.btn-action {
  background-image: none;
}

.btn-action.disabled,
.btn-action[disabled],
fieldset[disabled] .btn-action,
.btn-action.disabled:hover,
.btn-action[disabled]:hover,
fieldset[disabled] .btn-action:hover,
.btn-action.disabled:focus,
.btn-action[disabled]:focus,
fieldset[disabled] .btn-action:focus,
.btn-action.disabled:active,
.btn-action[disabled]:active,
fieldset[disabled] .btn-action:active,
.btn-action.disabled.active,
.btn-action[disabled].active,
fieldset[disabled] .btn-action.active {
  background-color: #0F1221;
  border-color: #0F1221;
}

.btn-action .badge {
  color: #0F1221;
  background-color: #ffffff;
}

@media (max-width: 940px) {
    .mvc-grid ~ .btn-action {
        position: fixed;
        margin: 0;
        bottom: 0;
        width: 100%;
        margin-left: -15px;
    }
}

@media (min-width: 768px) and (max-width: 940px) {
    .mvc-grid ~ .btn-action {
        margin-left: -12%;
        width: 107%;
    }
}

.mvc-grid-filter {
    background-image: url("../images/filter_ffffff_16.png");
}

.mvc-grid-filter.applied {
    background-image: url("../images/filter_6b6b6b_16.png");
}

.kaptl-logo {
    width: 48px;
}

.navbar.navbar-inverse {
    background-image: none;
    border-radius: 0;
}

.navbar-inverse { background-color: #000000; }
.navbar-inverse .navbar-nav>.active>a:hover,.navbar-inverse .navbar-nav>li>a:hover, .navbar-inverse .navbar-nav>li>a:focus { background-color: #FFFFFF}
.navbar-inverse .navbar-nav>.active>a,.navbar-inverse .navbar-nav>.open>a,.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:hover,.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:hover, .navbar-inverse .navbar-nav>.open>a:focus { background-color: #FFFFFF}
.navbar-inverse .dropdown-menu { background-color: #000000}
.navbar-inverse .dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus { background-color: #FFFFFF}
.navbar-inverse { background-image: none; }
.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus { background-image: none; }
.navbar-inverse { border-color: #000000}
.navbar-inverse .navbar-brand { color: #FFFFFF}
.navbar-inverse .navbar-brand:hover { color: #FFFFFF}
.navbar-inverse .navbar-nav>li>a { color: #FFFFFF}
.navbar-inverse .navbar-nav>li>a:hover, .navbar-inverse .navbar-nav>li>a:focus { color: #000000}
.navbar-inverse .navbar-nav>.active>a,.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:hover, .navbar-inverse .navbar-nav>.open>a:focus { color: #000000}
.navbar-inverse .navbar-nav>.active>a:hover, .navbar-inverse .navbar-nav>.active>a:focus { color: #000000}
.dropdown-menu>li>a { color: #FFFFFF}
.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus { color: #000000}
.navbar-inverse .navbar-nav>.dropdown>a .caret { border-top-color: #FFFFFF}
.navbar-inverse .navbar-nav>.dropdown>a:hover .caret { border-top-color: #000000}
.navbar-inverse .navbar-nav>.dropdown>a .caret { border-bottom-color: #FFFFFF}
.navbar-inverse .navbar-nav>.dropdown>a:hover .caret { border-bottom-color: #000000}

.checkbox input[type="checkbox"] {
    margin-left: 0;
}

.btn-negative-action {
  color: #000000;
  background-color: #BAE423;
  border-color: #BAE423;
  border-radius: 0;
}

.btn-negative-action:hover,
.btn-negative-action:focus,
.btn-negative-action:active,
.btn-negative-action.active,
.open .dropdown-toggle.btn-negative-action {
  color: #000000;
  background-color: #c5f128;
  border-color: #c5f127;
}

.btn-negative-action:active,
.btn-negative-action.active,
.open .dropdown-toggle.btn-negative-action {
  background-image: none;
}

.btn-negative-action.disabled,
.btn-negative-action[disabled],
fieldset[disabled] .btn-negative-action,
.btn-negative-action.disabled:hover,
.btn-negative-action[disabled]:hover,
fieldset[disabled] .btn-negative-action:hover,
.btn-negative-action.disabled:focus,
.btn-negative-action[disabled]:focus,
fieldset[disabled] .btn-negative-action:focus,
.btn-negative-action.disabled:active,
.btn-negative-action[disabled]:active,
fieldset[disabled] .btn-negative-action:active,
.btn-negative-action.disabled.active,
.btn-negative-action[disabled].active,
fieldset[disabled] .btn-negative-action.active {
  background-color: #BAE423;
  border-color: #BAE423;
}

.btn-negative-action .badge {
  color: #0F1221;
  background-color: #FFFFFF;
}

.btn-back {
    margin: 14px 0;
}

.mvc-grid-apply {
  color: #ffffff;
  background-color: #0F1221;
  border-color: #0F1221;
  border-radius: 0;
  margin: 10px 0;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

.mvc-grid-apply:hover,
.mvc-grid-apply:focus,
.mvc-grid-apply:active:focus,
.mvc-grid-apply.active,
.open .dropdown-toggle.mvc-grid-apply {
  color: #000;
  background-color: #eee;
  border-color: #0F1221;
}

.mvc-grid-apply:active,
.mvc-grid-apply.active,
.open .dropdown-toggle.mvc-grid-apply {
  background-image: none;
}

.mvc-grid-apply.disabled,
.mvc-grid-apply[disabled],
fieldset[disabled] .mvc-grid-apply,
.mvc-grid-apply.disabled:hover,
.mvc-grid-apply[disabled]:hover,
fieldset[disabled] .mvc-grid-apply:hover,
.mvc-grid-apply.disabled:focus,
.mvc-grid-apply[disabled]:focus,
fieldset[disabled] .mvc-grid-apply:focus,
.mvc-grid-apply.disabled:active,
.mvc-grid-apply[disabled]:active,
fieldset[disabled] .mvc-grid-apply:active,
.mvc-grid-apply.disabled.active,
.mvc-grid-apply[disabled].active,
fieldset[disabled] .mvc-grid-apply.active {
  background-color: #0F1221;
  border-color: #0F1221;
}

.mvc-grid-apply .badge {
  color: #0F1221;
  background-color: #ffffff;
}

.mvc-grid-cancel {
  color: #000000;
  background-color: #BAE423;
  border-color: #BAE423;
  border-radius: 0;
}

.mvc-grid-cancel:hover,
.mvc-grid-cancel:focus,
.mvc-grid-cancel:active:focus,
.mvc-grid-cancel:active,
.mvc-grid-cancel.active,
.open .dropdown-toggle.mvc-grid-cancel {
  color: #000000;
  background-color: #c5f128;
  border-color: #c5f127;
}

.mvc-grid-cancel:active,
.mvc-grid-cancel.active,
.open .dropdown-toggle.mvc-grid-cancel {
  background-image: none;
}

.mvc-grid-cancel.disabled,
.mvc-grid-cancel[disabled],
fieldset[disabled] .mvc-grid-cancel,
.mvc-grid-cancel.disabled:hover,
.mvc-grid-cancel[disabled]:hover,
fieldset[disabled] .mvc-grid-cancel:hover,
.mvc-grid-cancel.disabled:focus,
.mvc-grid-cancel[disabled]:focus,
fieldset[disabled] .mvc-grid-cancel:focus,
.mvc-grid-cancel.disabled:active,
.mvc-grid-cancel[disabled]:active,
fieldset[disabled] .mvc-grid-cancel:active,
.mvc-grid-cancel.disabled.active,
.mvc-grid-cancel[disabled].active,
fieldset[disabled] .mvc-grid-cancel.active {
  background-color: #BAE423;
  border-color: #BAE423;
}

.mvc-grid-cancel .badge {
  color: #0F1221;
  background-color: #FFFFFF;
}

.mvc-grid-popup {
    .btn-success {
        background-image: none;
    }
    .btn-danger {
        background-image: none;
    }
}

.block-ui-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 20%;
    i {
        color: #fff;
        opacity: 1;
        font-size: 64px;
    }
}